import { RECEIVE_PRODUCT_BRANDS} from '../actions/brands_actions';
  
  const BrandsReducer = (state = { productBrands: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_PRODUCT_BRANDS:
          newState.productBrands = action.productBrands.data;
          return newState;
      default:
        return state;
    }
  };
  
  export default BrandsReducer;