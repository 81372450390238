import React from 'react';
import './App.css';
import './animations.css'
import './navbar.css'
import './payment.css'
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import {AuthRoute, ProtectedRoute } from '../util/route_util';
import LandingPage from './landing_page_container';
import Footer from './footer_container';
import Page from './page_container';
import MobileNavbar from './mobilenavbar_container';
import Shop from './shop/shop_container';
import SelectedProduct from './shop/selected_product_container'
import Checkout from './cart/checkout';
import Success from './cart/success';
import InkBrush from './shop/inkbrush_container';
import ProductsByCategory from './shop/category_container';
import Collectionsbycategory from './shop/collectionsbycategory_container';
import PowderyPixel from './shop/powderypixel_container';
import DetailedDefinition from './shop/detaileddefinition_container';


function App() {
  return (
    <div className="App">
      <header>      

      </header>
        <Switch>
          <Route exact path='/' component={Shop} /> 
          <Route exact path='/:id' component={Shop} /> 
          <Route exact path='/:id/collections/:category' component={Collectionsbycategory} />
          <Route exact path='/:id/collections/:category/:name' component={SelectedProduct} />
          <Route exact path='/:id/collections/:category/cosmetink/inkbrush' component={InkBrush} />
          <Route exact path='/:id/collections/:category/cosmetink/powderypixel' component={PowderyPixel} />
          <Route exact path='/:id/collections/:category/cosmetink/detaileddefinition' component={DetailedDefinition} />
        </Switch>

      <footer>
      <Footer/>
    </footer>
    </div>
  );
}

export default App;
