import * as ProductAPIUtil from '../util/product_api_util';

export const RECEIVE_NEW_PRODUCT = "RECEIVE_NEW_PRODUCT";
export const RECEIVE_SELECTED_PRODUCT = "RECEIVE_SELECTED_PRODUCT";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_UPDATED_PRODUCT = "RECEIVE_UPDATED_PRODUCT";
export const RECEIVE_DELETED_PRODUCT = "RECEIVE_DELETED_PRODUCT";

export const RECEIVE_NEW_CATEGORY = "RECEIVE_NEW_CATEGORY";
export const RECEIVE_PRODUCT_CATEGORIES = "RECEIVE__PRODUCT_CATEGORIES";
export const RECEIVE_DELETED_CATEGORY = "RECEIVE_DELETED_CATEGORY";


export const RECEIVE_NEW_PRODUCT_SECTION = "RECEIVE_NEW_PRODUCT_SECTION";
export const RECEIVE_PRODUCT_SECTIONS = "RECEIVE_PRODUCT_SECTIONS";
export const RECEIVE_UPDATED_PRODUCT_SECTION = "RECEIVE_UPDATED_PRODUCT_SECTION";
export const RECEIVE_DELETED_PRODUCT_SECTION = "RECEIVE_DELETED_PRODUCT_SECTION";



export const receiveProducts = products => ({
    type: RECEIVE_PRODUCTS,
    products
});

export const receiveProductCategories = productCategories => ({
    type: RECEIVE_PRODUCT_CATEGORIES,
    productCategories
});


export const receiveSelectedProduct = selectedProduct => ({
    type: RECEIVE_SELECTED_PRODUCT,
    selectedProduct
});


export const fetchProducts = (site_id, category) => dispatch => {
    ProductAPIUtil.fetchProducts(site_id, category)
    .then(products => dispatch(receiveProducts(products)));
}

export const fetchSelectedProduct = (site_id, selectedProduct) => dispatch => {
    ProductAPIUtil.fetchSelectedProduct(site_id, selectedProduct)
    .then(selectedProduct => dispatch(receiveSelectedProduct(selectedProduct)));
}

export const fetchProductCategories = (site_id) => dispatch => {
    ProductAPIUtil.fetchProductCategories(site_id)
    .then(productCategories => dispatch(receiveProductCategories(productCategories)));
}



