import { connect } from 'react-redux';
import { fetchSite } from '../../actions/site_actions';
import { fetchSections } from '../../actions/section_actions';
import { fetchProductCategories, fetchProducts, fetchSelectedProduct } from '../../actions/product_actions'
import { fetchProductBrands} from '../../actions/brands_actions'
import Collectionsbycategory from './collectionsbycategory';

const mapStateToProps = (state) => {
  return {
    site: state.sites.site,
    products: state.product.products,
    productCategories: state.product.productCategories,  
    productBrands: state.brands.productBrands
};
};

const mapDispatchToProps = (dispatch) => {
    return {
      fetchSite: site_id => dispatch(fetchSite(site_id)),
      fetchSections: site_id => dispatch(fetchSections(site_id)),
      fetchSelectedProduct: (site_id, product_name) => dispatch(fetchSelectedProduct(site_id, product_name)),
      fetchProducts: (site_id, category) => dispatch(fetchProducts(site_id, category)),
      fetchProductCategories: site_id => dispatch(fetchProductCategories(site_id)),
      fetchProductBrands: site_id => dispatch(fetchProductBrands(site_id))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Collectionsbycategory);