import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

export default function Footer(props)  {
    const [menu, setMenu] = useState(false)

    const { fetchFooter, footer, site} = props

   /* useEffect(() => {
        if (site) {
        fetchFooter(site[0]._id)
        }
    }, [site]) */

        return (
            footer ? (
            <>
            <div className="mobile-nav-container">
            <div className="mobile-navbar">
                {Object.keys(footer).map((key) => {
                    return (
                        <>
                        <Link className="mobile-navbar-link" to={`/${site[0]._id}${footer[key].link_to}`}><i className={`${footer[key].icon}`}></i><br/>{footer[key].name}</Link>
                        </>
                    )
                })}
            </div>
            </div>
            </>
            ) : null
       
        )
    }