import { combineReducers } from 'redux';
import session from './session_api_reducer';
import sessionErrors from './session_errors_reducer'
import sites from './sites_api_reducer'
import product from './product_api_reducer';
import sections from './sections_api_reducer';
import transaction from './transaction_api_reducer';
import brands from './brands_api_reducers';

const RootReducer = combineReducers({
  sites,
  session,
  sessionErrors,
  product,
  transaction,
  sections,
  brands
});

export default RootReducer;