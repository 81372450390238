import { connect } from 'react-redux';
import { fetchFooter } from '../actions/site_actions';
import Footer from './footer';

const mapStateToProps = (state) => {
  return {
    site: state.sites.site,
    footer: state.sites.footer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFooter: site_id => dispatch(fetchFooter(site_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);