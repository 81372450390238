import * as SectionAPIUtil from '../util/section_api_util';


export const RECEIVE_SECTIONS = "RECEIVE_SECTIONS";


export const receiveSections = sections => ({
    type: RECEIVE_SECTIONS,
    sections
});


export const fetchSections = (site_id) => dispatch => {
    SectionAPIUtil.fetchSections(site_id)
    .then(sections => dispatch(receiveSections(sections)));
}