import { RECEIVE_PRODUCTS, RECEIVE_PRODUCT_CATEGORIES, RECEIVE_SELECTED_PRODUCT} from '../actions/product_actions';
  
  const ProductReducer = (state = { products: undefined, productCategories: undefined, selectedProduct: undefined }, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_PRODUCTS:
        newState.products = action.products.data;
        return newState;
      case RECEIVE_PRODUCT_CATEGORIES:
        newState.productCategories = action.productCategories.data;
        return newState;
      case RECEIVE_SELECTED_PRODUCT:
        newState.selectedProduct = action.selectedProduct.data;
        return newState;
      default:
        return state;
    }
  };
  
  export default ProductReducer;