import { connect } from 'react-redux';
import { fetchSite } from '../actions/site_actions';
import LandingPage from './landing_page';

const mapStateToProps = (state) => {
  return {
    site: state.sites.site
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSite: site_id => dispatch(fetchSite(site_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage);