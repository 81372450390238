import { connect } from 'react-redux';
import { fetchSite } from '../../actions/site_actions';
import { fetchProductCategories, fetchSelectedProduct, fetchProducts } from '../../actions/product_actions'
import Shop from './shop';
import SelectedProduct  from './selected_product';

const mapStateToProps = (state) => {
  return {
    site: state.sites.site,
    product: state.product.selectedProduct,
    products: state.product.products
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSite: site_id => dispatch(fetchSite(site_id)),
    fetchProducts: (site_id, category) => dispatch(fetchProducts(site_id, category)),
    fetchSelectedProduct: (site_id, product_name) => dispatch(fetchSelectedProduct(site_id, product_name)),
    fetchProductCategories: site_id => dispatch(fetchProductCategories(site_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedProduct);