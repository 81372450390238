import {RECEIVE_SECTIONS} from '../actions/section_actions';
  
  const SectionReducer = (state = { sections: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_SECTIONS:
        newState.sections = action.sections.data;
        return newState;
      default:
        return state;
    }
  };
  
  export default SectionReducer;