import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import ProductsByCategory from './category_container';
import SelectedProduct from './selected_product';
import MobileNavbar from '../mobilenavbar_container'
import Cartsum from '../cart_sum_container'

export default function Shop(props) {
  

  const { fetchSite, site, 
          fetchProducts, fetchProductCategories,
          productCategories, fetchSelectedProduct,
        fetchSections, sections, match } = props

  const [siteId, setSiteId] = useState(null)
  const [state, setState] = useState(false)
  const [categories, setCategories] = useState(null)
  const [viewBySelected, setViewBySelected] = useState(2)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [open, setOpen] = useState(true)
  const [products, setProducts] = useState([])
  const [update, setUpdate] = useState(false)
  const [cart, setCart] = useState(false)
  const [added, setAdded] = useState(null)

  const subdomain = window.location.hostname.split('.')[0]
  const domain = window.location.href.split('.')[1]
  var id = match.params.id ? match.params.id : domain
  console.log(id)
  console.log(domain)
  console.log(window.location.hostname)
  console.log(window.location)

useEffect(() => {
    if (!site)
    fetchSite(id)
}, [site]) 

useEffect(() => {
  if (site) {
  setSiteId(site[0]._id)
  fetchSections(site[0]._id)
  }
}, [site]) 

useEffect(() => {
  if (site && siteId)
  fetchProductCategories(siteId)
 
}, [site, siteId]) 

useEffect(() => {
  if (productCategories)
  setCategories(productCategories)
}, [productCategories]) 



    
function showCart() {
  cart ? setCart(false) : setCart(true)
}

function openShop() {
  open ? setOpen(false) : setOpen(true)
}

let total = 0

const $CART = {
  KEY: 'cosmetinkshopcartA2',
  contents: [],
 
  init(){
    //check local storage and initialize the contents of CART.contents
    let _contents = localStorage.getItem($CART.KEY);
    if (_contents) {
        
      $CART.contents = JSON.parse(_contents);

    } else {
      $CART.contents = [
      ];
      $CART.sync()
   
    }
  
  },


  async sync() {
    let _cart = JSON.stringify($CART.contents);
    await localStorage.setItem($CART.KEY, _cart);

    
  },
  find(id) {
  let match = $CART.contents.filter(item => {
    if(item.id == id)
    return true;
  });
  if (match && match[0])
  return match[0];
},
add(prod) {
  if($CART.find(prod._id)) {
    $CART.increase(prod._id, 1);
 
  }else{
    let arr = PRODUCTS.filter(product => {
      if(product._id == prod._id) {
        return true;
      }


    });
    if (arr && arr[0]) {
      let obj = {
        id: arr[0]._id,
        title: arr[0].name,
        qty: 1,
        itemCost: arr[0].cost,
        image: arr[0].image1
      };
      $CART.contents.push(obj);
      $CART.sync()
 
    } else {
      console.error("Invalid Product")
    }
  }
  $CART.findSum()
},
increase(id, qty=1) {
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty + qty;
      return item;
  });
  $CART.sync()
  updateCart()

},
reduce(id, qty=1){
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty - qty;
      return item;
  });
  $CART.contents.forEach(async item => {
    if(item.id === id && item.qty === 0)
    $CART.remove(id);
  });
  $CART.sync()
  updateCart()
},
remove(id) {
  $CART.contents = $CART.contents.filter(item => {
    if(item.id !== id)
    return true;
  })
  $CART.sync()
  updateCart()
},
empty() {
  $CART.contents = [];
  $CART.sync()
  updateCart()
},

setTransactionID(transId) {
  $CART.transactionID = transId
},

findSum() {

      let sum = $CART.contents.forEach(item => {
          total = total + item.qty
          return sum
      })

    
     
},

sort(field='title') {
  let sorted = $CART.contents.sort( (a, b) => {
    if (a[field] > b[field]) {
      return 1;
    }else if(a[field] < b[field]){
      return -1;
    }else {
      return 0
    }
  });
  return sorted;
}

}

function addToCart(prod) {
 
  $CART.add(prod)
  showCart()
  setAdded(prod._id)
  setTimeout(() => {
    setAdded(null)
  }, 400);
}


function updateCart() {
  update ? setUpdate(false) : setUpdate(true)
}


let PRODUCTS = []
PRODUCTS = products
$CART.init()
$CART.findSum()


    
      return (

        

      
      categories && siteId && !selectedProduct ? (

  
        
        <div className=" fade-in ">
          <div className="">
        <Cartsum cartupdate={cart}/>
        { sections && Object.keys(sections).map((key) => {
         return (
          <>
          { sections[key].video_url ?
           <div className="front-page-vid-container">
             <a href={`${sections[key].linkto}`}>
             <video className={`width-100 fit-abs`} preload="none" poster={`${sections[key].video_poster}`} 
             loop muted defaultMuted autoPlay controls = '' playsInline src={window.innerWidth < 800 ? `${sections[key].video_url}` : `${sections[key].desktopVideoURL}`} />
             </a>


            
                      
              <div className={"top70"}>
                  <div className="entry-text-low">
                  <div className="uppercase wht"><span className="italic-font wht">{`${sections[key].italic}`} </span>{`${sections[key].line1}`}</div>
                      <div className="uppercase black-friday-special">{`${sections[key].line2}`}</div>

                      </div>

                      </div>
           </div> : null }


      


           {sections[key].imageURL ? 
           <div className={sections[key].black ? `inline-block section black-back` : `inline-block section gray-back`}>
          
           <div >

          { sections[key].background == "white-back" ?
          <>
          <br/>
          <h1 className={sections[key].black ? 'sanz-thin apple-h3' : `sanz-thin apple-h3 blk`} >
          {sections[key].line1}
          </h1> 
          <h1 className={sections[key].black ? 'sanz-thin apple-h2' : 'sanz-thin apple-h2'}>
            {sections[key].line2}
          </h1></>: 
          <>
          <h1 className={sections[key].black ? 'sanz-thin apple-h3' : `sanz-thin apple-h3 blk`}>
              {sections[key].line1}
            </h1>
            <h1 className={sections[key].black ? 'sanz-thin apple-h2' : 'sanz-thin apple-h2'}>
            {sections[key].line2}
            </h1>
            </> }

          <div className={sections[key].black ? 'wht sanz-reg inkbrush-text fade-in line3' : 'blk sanz-reg inkbrush-text fade-in line3'}>
            {sections[key].line3}
          </div>

          {sections[key].background !== "white-back" ? 
          <>
          <div className='purple-back-frontpg fade-in-and-out'>
          </div>
                
          <div className='pink-back-frontpg fade-in-and-out2'>
          </div>
          </> : 
          <>
          <div>
          <div className='yellow-back-frontpg fade-in-and-out2'>
          </div>

          <div className='white-back-frontpg fade-in-and-out1'>
          </div>
          </div>
          </>}

           <img className='product-section-image' src={`${sections[key].imageURL}`}></img>
           <div className='section-call-to-action'>
            <a className='call-to-action' href={`${sections[key].callToActionLink}`}>{sections[key].callToAction} &nbsp;<i class="fa-regular fa-chevron-right"></i>
            </a>
           </div>
           </div> </div>: null}
           
           </>
         )
       })}
       <div className='footer c'>
          Copyright © 2023 COSMETINK, LLC. All Rights Reserved.<br/><br/>
     Email us at <a className="mailto" href='mailto:cosmetinkbeauty@gmail.com'>cosmetinkbeauty@gmail.com</a> if you have any questions.
     </div>

          </div></div> ) : <>

          </>
        );
    }