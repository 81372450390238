import * as TransactionAPIUtil from '../util/transaction_api_util';

export const RECEIVE_NEW_TRANSACTION = "RECEIVE_NEW_TRANSACTION";

export const receiveTransaction = transaction => ({
    type: RECEIVE_NEW_TRANSACTION,
    transaction
});

export const createTransaction = (transactionData) => dispatch => {
    TransactionAPIUtil.createTransaction(transactionData)
    .then(transaction => dispatch(receiveTransaction(transaction)));
}