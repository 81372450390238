import { RECEIVE_SITE, RECEIVE_FOOTER,
         RECEIVE_MOBILE_NAVBAR,
         RECEIVE_TEAM_MEMBERS,
         RECEIVE_TEAM_MEMBER } from '../actions/site_actions';
  
  const SitesReducer = (state = { site: undefined, footer: undefined, 
                        mobilenavbar: undefined,
                        team: undefined, member: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_SITE:
        newState.site = action.site.data;
        return newState;
      case RECEIVE_FOOTER:
        newState.footer = action.footer.data;
        return newState;
      case RECEIVE_MOBILE_NAVBAR:
        newState.mobilenavbar = action.mobilenavbar.data;
        return newState;
      case RECEIVE_TEAM_MEMBERS:
        newState.team = action.team.data;
        return newState;
      case RECEIVE_TEAM_MEMBER:
        newState.member = action.member.data;
        return newState;
      default:
        return state;
    }
  };
  
  export default SitesReducer;