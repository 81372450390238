import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Product from './product';


export default function ProductsByCategory(props) {

  const { setProducts, products, fetchProducts, category, siteId, view,
  setSelectedProduct, selectedProduct, siteName, image, addToCart, added } = props
  const [productSelection, setProductSelection] = useState(null)
  
  useEffect(() => {
    if (category)
    fetchProducts(siteId, category)
}, [category]) 

useEffect(() => {
  if (products)
  setProductSelection(products)

}, [products]) 


    return (
      productSelection && !selectedProduct ?
      <>

      <div className="fade-in product-image-gallery">

      {Object.keys(productSelection).map((key) => {
        return (
          <Product
          product = {productSelection[key]}
          bname={productSelection[key].bname}
          name={productSelection[key].name}
          image1={productSelection[key].image1}
          image2={productSelection[key].image2}
          desc={productSelection[key].desc}
          desc2={productSelection[key].desc2}
          cost={productSelection[key].cost}
          size={productSelection[key].size}
          subcategory={productSelection[key].subcategory}
          view={view}
          siteName={siteName}
          addToCart={addToCart}
          added={added}
          setSelectedProduct={setSelectedProduct}
          key={productSelection[key]._id}
          />
        )
      })}
       





      </div>
      </> : <></>
        );
    }