import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

export default function Cart(props)  {
    

    const { contents, open, findSum,
            remove, reduce, increase, showCart } = props
        
    const [state, setState] = useState(false)
            
            useEffect(() => {
                if (open) {
                    state ? setState(false) : setState(true)
                }
            }, [open]) 


    let cartTotal = 0
    let qty = 0

        return (
        
   
            <>

            { open ?
            <>
          
   
            <div className="cart-container slide-in-right">
           
                <div onClick={showCart}  className="category-label-2 c cart-title light-box-shadow">
               
                    <span className='float-left gradientized'>
                    <i class="fa-regular fa-chevron-left"></i> Go Back
                    </span>
                </div>
                   
                <div onClick={showCart}  className="category-label-2 c cart-title">
               
                    Your Cart
                </div>
           
           
                <div className="cart-scroll">
                {contents.length === 0 || contents.empty ?
                                <div className="category-label c trans-back pad-20">
                                You have 0 items in your cart.
                                <br/><br/><br/>
                                </div> : 
                Object.keys(contents).map((key) => {
                    let id = contents[key].id
                
                        cartTotal = cartTotal + contents[key].qty * contents[key].itemCost 
                    
                    return (
                        <div key={key} className="cart-item">
                        <span className="third">
                            <div className="left">
                            <img className="cart-image" src={contents[key].image}></img>
                            </div>
                        </span>
                        <span className="third">
                        <div className="cart-item-name">{contents[key].title}</div>
                        <br/>

                  
                                <span onClick={() => reduce(id)} className="quantity-btn">
                                    -
                                </span>
     
                            <span className="quantity-btn num">
                             
                                    {contents[key].qty}
                             
                            </span>
      
                                <span onClick={() => increase(id)} className="quantity-btn">
                                    +
                                </span>
                                <br/>
                                <br/>
                        <div onClick={() => remove(id)} className="cart-item-name link">Remove item</div>
                        </span>
                        <span className="third">
                        <div className="right">
                  
                        ${(contents[key].qty * parseInt(contents[key].itemCost)).toFixed(2)}
                        </div>
                        
                        </span>
                        </div>)
                })}
                        <div className="cart-item border-top pad-top-btm">
                            <span className="third">
                                <div className="left">
                                Subtotal
                                </div>
                            </span>
                            <span className="third">

                            </span>
                            <span className="third">
                                <div className="right">
                                ${cartTotal.toFixed(2)}
                                </div>
                            </span> 
       
                        </div>
                        
                
                </div>

            </div> </>: <div className="slide-in-left">
            </div> }
            </>

      
       
        )
    }