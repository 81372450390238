import * as SiteAPIUtil from '../util/sites_api_util';

export const RECEIVE_SITE = "RECEIVE_SITE";
export const RECEIVE_FOOTER = "RECEIVE_FOOTER";
export const RECEIVE_MOBILE_NAVBAR = "RECEIVE_MOBILE_NAVBAR";
export const RECEIVE_TEAM_MEMBERS = "RECEIVE_TEAM_MEMBERS";
export const RECEIVE_TEAM_MEMBER = "RECEIVE_TEAM_MEMBER";

export const receiveSite = site => ({
    type: RECEIVE_SITE,
    site
});

export const receiveFooter = footer => ({
    type: RECEIVE_FOOTER,
    footer
});

export const receiveMobileNavbar = mobilenavbar => ({
    type: RECEIVE_MOBILE_NAVBAR,
    mobilenavbar
});

export const receiveTeamMembers = team => ({
    type: RECEIVE_TEAM_MEMBERS,
    team
});

export const receiveTeamMember = member => ({
    type: RECEIVE_TEAM_MEMBER,
    member
});

export const fetchSite = site_id => dispatch => {
    SiteAPIUtil.fetchSite(site_id).then(site => dispatch(receiveSite(site)));
}

export const fetchFooter = site_id => dispatch => {
    SiteAPIUtil.fetchFooter(site_id).then(footer => dispatch(receiveFooter(footer)));
}

export const fetchMobileNavbar = site_id => dispatch => {
    SiteAPIUtil.fetchMobileNavbar(site_id).then(mobilenavbar => dispatch(receiveMobileNavbar(mobilenavbar)));
}

export const fetchTeamMembers = site_id => dispatch => {
    SiteAPIUtil.fetchTeamMembers(site_id).then(team => dispatch(receiveTeamMembers(team)));
}

export const fetchTeamMember = (site_id, name) => dispatch => {
    SiteAPIUtil.fetchTeamMember(site_id, name).then(member => dispatch(receiveTeamMember(member)));
}

