import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Cartsum from '../cart_sum_container'

export default function PowderyPixel(props) {

  const {fetchSite, site, match, product, fetch, products,
    fetchProductCategories, fetchProducts, fetchSelectedProduct, siteName } = props
  const [siteId, setSiteId] = useState(null)
  const [productName, setProductName] = useState(null)
  const domain = window.location.href.split('.')[1]
  var id = match.params.id ? match.params.id : domain
  var name = "powderypixelb"
  var category = match.params.category
  const [productObj, setProductObj] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(true)
  const [update, setUpdate] = useState(false)
  const [cart, setCart] = useState(false)
  const [added, setAdded] = useState(null)

  useEffect(() => {
    if (!site)
    fetchSite(id)
}, [site]) 

useEffect(() => {
    if (site && siteId)
    fetchProductCategories(siteId)
  }, [site, siteId]) 

useEffect(() => {
  if (site)
  setSiteId(site[0]._id)
}, [site]) 

useEffect(() => {
    if (siteId && category)
    fetchProducts(siteId, category)
}, [siteId, category]) 


useEffect(() => {
    setProductName("s")
  }, []) 

  useEffect(() => {
    if (productName && siteId)
    fetchSelectedProduct(siteId, productName)
  }, [productName, siteId]) 
  
  useEffect(() => {
    if (product)
    setProductObj(product[0])
  }, [product]) 
  

function openDiscover() {
    isOpen ? setIsOpen(false) : setIsOpen(true)
}

function showCart() {
    cart ? setCart(false) : setCart(true)
  }
  
  function openShop() {
    open ? setOpen(false) : setOpen(true)
  }

let total = 0

const $CART = {
  KEY: 'cosmetinkshopcartA2',
  contents: [],
 
  init(){
    //check local storage and initialize the contents of CART.contents
    let _contents = localStorage.getItem($CART.KEY);
    if (_contents) {
        
      $CART.contents = JSON.parse(_contents);

    } else {
      $CART.contents = [
      ];
      $CART.sync()
   
    }
  
  },


  async sync() {
    let _cart = JSON.stringify($CART.contents);
    await localStorage.setItem($CART.KEY, _cart);

    
  },
  find(id) {
  let match = $CART.contents.filter(item => {
    if(item.id == id)
    return true;
  });
  if (match && match[0])
  return match[0];
},
add(prod) {
  if($CART.find(prod._id)) {
    $CART.increase(prod._id, 1);
 
  }else{
    let arr = PRODUCTS.filter(product => {
      if(product._id == prod._id) {
        return true;
      }


    });
    if (arr && arr[0]) {
      let obj = {
        id: arr[0]._id,
        title: arr[0].name,
        qty: 1,
        itemCost: arr[0].cost,
        image: arr[0].image1
      };
      $CART.contents.push(obj);
      $CART.sync()
 
    } else {
      console.error("Invalid Product")
    }
  }
  $CART.findSum()
},
increase(id, qty=1) {
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty + qty;
      return item;
  });
  $CART.sync()
  updateCart()

},
reduce(id, qty=1){
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty - qty;
      return item;
  });
  $CART.contents.forEach(async item => {
    if(item.id === id && item.qty === 0)
    $CART.remove(id);
  });
  $CART.sync()
  updateCart()
},
remove(id) {
  $CART.contents = $CART.contents.filter(item => {
    if(item.id !== id)
    return true;
  })
  $CART.sync()
  updateCart()
},
empty() {
  $CART.contents = [];
  $CART.sync()
  updateCart()
},

setTransactionID(transId) {
  $CART.transactionID = transId
},

findSum() {

      let sum = $CART.contents.forEach(item => {
          total = total + item.qty
          return sum
      })

    
     
},

sort(field='title') {
  let sorted = $CART.contents.sort( (a, b) => {
    if (a[field] > b[field]) {
      return 1;
    }else if(a[field] < b[field]){
      return -1;
    }else {
      return 0
    }
  });
  return sorted;
}

}

function addToCart(prod) {
 
  $CART.add(prod)
  showCart()
  setAdded(prod._id)
  setTimeout(() => {
    setAdded(null)
  }, 400);
}


function updateCart() {
  update ? setUpdate(false) : setUpdate(true)
}


let PRODUCTS = []
PRODUCTS = products
$CART.init()
$CART.findSum()

    return (

      <div className="shop-main ">
      <Cartsum />
      <div className="shop slide-in-bottom ">
        <br/><br/>
      <div onClick={openDiscover} className={isOpen ? `category-label  gray-font blk no-caps hidden` : `category-label gray-font blk no-caps border-top-bottom hidden`}>Discover Powdery Pixel <span className='abs-right blk'>{isOpen ? <i class="fa-light fa-chevron-up"></i> : <i class="fa-light fa-chevron-down"></i>}</span></div>
      {isOpen ? 
      <div className='discover-links blk'>
        <div className='gray-font discover-link slide-in-top blk'>Full Overview</div>
        <div className='gray-font discover-link slide-in-top blk'>Show Me The Specs</div>
      </div> : null}
      <br/><br/>


    <div className={isOpen ? 'opacity-7' : null}>
  
      <div className="fade-in selected-product height-60">
        <br/>
        <br/>

    <h1 className='sanz-thin apple-h3 blk'>
        Powdery
    </h1>
    <h1 className='sanz-thin apple-h2'>
        Pixel
    </h1>
    <br/>
    <div className='gray-font sanz-reg inkbrush-text fade-in'><span className="gray-font wht sanz-regitalic"></span></div>
      
    
      
    <div className='purple-back fade-in-and-out'>
    </div>

    <div className='pink-back fade-in-and-out2'>
    </div>


       <img className='width-100 inkbrush-image' src={`https://cosmetinkshop.s3.us-east-2.amazonaws.com/IMG_2131.PNG`}></img>

      </div>
      
      <div className='gray-font blk sanz-reg inkbrush-text fade-in'>Designed for that Evenly Shaded and Perfect Powdery Effect</div>
      
      </div>
      </div> 
      <div className='apple-gray-back'>
        <div className='gray-font sanz-thin'>
        Powdery<span className='ink-gradient'>PIXEL™</span>
        </div>

 
        <div className='deeper-gray-font'>
        $32 per box <br/>Includes 20 cartridges<br/>
        Sizes available: .25mm, .30mm, .35mm 1RL 
        </div>

        <select onChange={(e) => setProductName(e.currentTarget.value)} id="size" name="size">
        <option value="">Select Size</option>
        <option value="powderypixel">.25mm 1RL</option>
        <option value="powderypixelb">.30mm 1RL</option>
        <option value="powderypixelc">.35mm 1RL</option>
        </select>
        <br/>
        <div className=''>
            <a className='purp-btn' href="mailto:cosmetinkbeauty@gmail.com?subject=InkBRUSH Financing&body=I would like to explore my financing options for the InkBRUSH.">Email to Apply for Financing &nbsp;<i class="fa-thin fa-envelope-open-dollar"></i></a>
        </div>
      
        <div className='detail-box'>
        <div className="width-100 upcase let-spacing-5 font-size-9pt selected text-left main-product-name wht l">
                Description
        </div>
        <div className='box-desc wht'>
        <span className="bold wht">COSMETINK PRO Powdery Pixel™</span> needles are revolutionary PMU needles designed to create evenly shaded pixels for that perfect powdery effect.

        <br/><br/>
        <span className="bold wht">COSMETINK PRO Powdery Pixel™</span> needle cartridges are designed to be soft and gentle, yet ultra precise, for all skin types.
        </div>
        <br/>
        <div className="width-100 upcase let-spacing-5 font-size-9pt selected text-left main-product-name wht l">
                Specs
        </div>
        <div className='box-desc wht'>
            <ul className='wht'>• 20 cartridges per box</ul>
           <ul className='wht'>• Needle stabilizing system</ul>
           <ul className='wht'>• Ultra precise, yet super gentle for the skin</ul>
           <ul className='wht'>• 0.25, 0.30mm, & 0.35 diameter needle</ul>
           <ul className='wht'>• Full membrane</ul>
           <ul className='wht'>• Medical grade plastic</ul>
           <ul className='wht'>• Exceptional ink flow</ul>
           <ul className='wht'>• GSurgical stainless steel needles</ul>
           <ul className='wht'>• E.O. Gas Sterilized</ul>
           <ul className='wht'>• Compatible with all rotary machines</ul>
           <br/>
        
        </div>
        </div>

      </div>


        
      </div> 
        );
    }