import React, { useState } from 'react';
import {Link} from 'react-router-dom'


export default function Product(props) {

  const { name, size, image1, image2, desc, desc2, cost, view, setSelectedProduct, product, siteName, 
          category, bname, subcategory, addToCart, added, key } = props
  

          
    return (
      <div className="product-container fade-in">
      <Link category={category} siteName={siteName} product={product} to={`${category}/${name}`} className={''}>

       <div className="product-image-box light-box-shadow">
       <img className="product " src={`${image1}`} />
       </div>
       <div className="product-name no-pad pad-left-5-p">
       <div className="upcase  bname pad-5">{bname}</div>
       <div className="upcase let-spacing-5 font-size-85pt font-400 pad-5 name2">{name}&nbsp;&nbsp;<span>{size}</span></div>
       <div className="upcase let-spacing-5 font-size-85pt cost2 font-400">${cost}</div>
       </div>
       </Link>
       <button onClick={() => addToCart(product)} className="add-to-cart-small"><div className="add-text upcase">{added === product._id ? `✓` : <i class="fa-solid fa-cart-circle-plus"></i>}</div></button>
      </div>

  


        );
    }