import { connect } from 'react-redux';
import { fetchTeamMember, fetchSite } from '../actions/site_actions';
import Page from './page';

const mapStateToProps = (state) => {
  return {
    site: state.sites.site,
    member: state.sites.member
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSite: site_id => dispatch(fetchSite(site_id)),
    fetchTeamMember: (site_id, name) => dispatch(fetchTeamMember(site_id, name))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);