import { RECEIVE_NEW_TRANSACTION} from '../actions/transaction_actions';
  
  const TransactionReducer = (state = { transaction: undefined}, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_NEW_TRANSACTION:
        newState.transaction = action.transaction.data;
        return newState;
      default:
        return state;
    }
  };
  
  export default TransactionReducer;