import { connect } from 'react-redux';
import ProductsByCategory from './category';
import { fetchProducts } from '../../actions/product_actions';


const mapStateToProps = (state) => {
  return {
      products: state.product.products
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProducts: (site_id, category) => dispatch(fetchProducts(site_id, category)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsByCategory);