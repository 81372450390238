import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

export default function Team(props)  {

    const { site, team, fetchTeamMembers} = props
 

   /* useEffect(() => {
        if (!team && site) {
            fetchTeamMembers(site[0]._id)
        }
    }, [team, site]) */

        return (
            team ? (
            <>
            {Object.keys(team).map((key) => {
                return (
                    <div className="artist">
                    <Link className="artist-link" to={`${team[key].link_to}`}><img className="artist-image" src={team[key].image}/></Link>
                    </div>
                )
            })}

            </>
            ) : null
       
        )
    }