import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Cartsum from '../cart_sum_container'

export default function InkBrush(props) {

  const {fetchSite, site, match, product, fetch, products,
    fetchProductCategories, fetchProducts, fetchSelectedProduct, siteName } = props
  const [siteId, setSiteId] = useState(null)
  const [productName, setProductName] = useState(null)
  const domain = window.location.href.split('.')[1]
  var id = match.params.id ? match.params.id : domain
  var name = "inkbrush"
  var category = match.params.category
  const [productObj, setProductObj] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [open, setOpen] = useState(true)
  const [update, setUpdate] = useState(false)
  const [cart, setCart] = useState(false)
  const [added, setAdded] = useState(null)

  useEffect(() => {
    if (!site)
    fetchSite(id)
}, [site]) 

useEffect(() => {
    if (site && siteId)
    fetchProductCategories(siteId)
  }, [site, siteId]) 

useEffect(() => {
  if (site)
  setSiteId(site[0]._id)
}, [site]) 

useEffect(() => {
    if (siteId && category)
    fetchProducts(siteId, category)
}, [siteId, category]) 


useEffect(() => {
    setProductName("inkbrush")
  }, []) 

  useEffect(() => {
    if (name && siteId)
    fetchSelectedProduct(siteId, name)
  }, [name, siteId]) 
  
  useEffect(() => {
    if (product)
    setProductObj(product[0])
  }, [product]) 
  

function openDiscover() {
    isOpen ? setIsOpen(false) : setIsOpen(true)
}

function showCart() {
    cart ? setCart(false) : setCart(true)
  }
  
  function openShop() {
    open ? setOpen(false) : setOpen(true)
  }

let total = 0

const $CART = {
  KEY: 'cosmetinkshopcartA2',
  contents: [],
 
  init(){
    //check local storage and initialize the contents of CART.contents
    let _contents = localStorage.getItem($CART.KEY);
    if (_contents) {
        
      $CART.contents = JSON.parse(_contents);

    } else {
      $CART.contents = [
      ];
      $CART.sync()
   
    }
  
  },


  async sync() {
    let _cart = JSON.stringify($CART.contents);
    await localStorage.setItem($CART.KEY, _cart);

    
  },
  find(id) {
  let match = $CART.contents.filter(item => {
    if(item.id == id)
    return true;
  });
  if (match && match[0])
  return match[0];
},
add(prod) {
  if($CART.find(prod._id)) {
    $CART.increase(prod._id, 1);
 
  }else{
    let arr = PRODUCTS.filter(product => {
      if(product._id == prod._id) {
        return true;
      }


    });
    if (arr && arr[0]) {
      let obj = {
        id: arr[0]._id,
        title: arr[0].name,
        qty: 1,
        itemCost: arr[0].cost,
        image: arr[0].image1
      };
      $CART.contents.push(obj);
      $CART.sync()
 
    } else {
      console.error("Invalid Product")
    }
  }
  $CART.findSum()
},
increase(id, qty=1) {
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty + qty;
      return item;
  });
  $CART.sync()
  updateCart()

},
reduce(id, qty=1){
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty - qty;
      return item;
  });
  $CART.contents.forEach(async item => {
    if(item.id === id && item.qty === 0)
    $CART.remove(id);
  });
  $CART.sync()
  updateCart()
},
remove(id) {
  $CART.contents = $CART.contents.filter(item => {
    if(item.id !== id)
    return true;
  })
  $CART.sync()
  updateCart()
},
empty() {
  $CART.contents = [];
  $CART.sync()
  updateCart()
},

setTransactionID(transId) {
  $CART.transactionID = transId
},

findSum() {

      let sum = $CART.contents.forEach(item => {
          total = total + item.qty
          return sum
      })

    
     
},

sort(field='title') {
  let sorted = $CART.contents.sort( (a, b) => {
    if (a[field] > b[field]) {
      return 1;
    }else if(a[field] < b[field]){
      return -1;
    }else {
      return 0
    }
  });
  return sorted;
}

}

function addToCart(prod) {
 
  $CART.add(prod)
  showCart()
  setAdded(prod._id)
  setTimeout(() => {
    setAdded(null)
  }, 400);
}


function updateCart() {
  update ? setUpdate(false) : setUpdate(true)
}


let PRODUCTS = []
PRODUCTS = products
$CART.init()
$CART.findSum()

    return (

      <div className="shop-main black-back">
      <Cartsum />
      <div className="shop slide-in-bottom black-back">
        <br/><br/>
      <div onClick={openDiscover} className={isOpen ? `category-label black-back gray-font no-caps hidden` : `category-label black-back gray-font no-caps border-top-bottom hidden`}>Discover INKBrush <span className='abs-right'>{isOpen ? <i class="fa-light fa-chevron-up"></i> : <i class="fa-light fa-chevron-down"></i>}</span></div>
      {isOpen ? 
      <div className='discover-links'>
        <div className='black-back gray-font discover-link slide-in-top'>Full Overview</div>
        <div className='black-back gray-font discover-link slide-in-top'>Show Me The Specs</div>
      </div> : null}
      <br/><br/>


    <div className={isOpen ? 'opacity-7' : null}>
  
      <div className="fade-in selected-product height-60">
        <br/>
        <br/>

    <h1 className='sanz-thin apple-h2'>
        INK
    </h1>
    <h1 className='sanz-thin apple-h3'>
        BRUSH.
    </h1>
    <br/>
    <div className='gray-font sanz-reg inkbrush-text fade-in'>It's <span className="gray-font wht sanz-regitalic">Revolutionary.</span></div>
      
    
      
    <div className='purple-back fade-in-and-out'>
    </div>

    <div className='pink-back fade-in-and-out2'>
    </div>


       <img className='inkbrush-image' src={`https://cosmetinkshop.s3.us-east-2.amazonaws.com/inkbrush.PNG`}></img>

      </div>
      
      <div className='gray-font sanz-reg inkbrush-text fade-in'>Designed to Feel <span className="gray-font sanz-regitalic">And Look</span><br/>Like You're Airbrushing</div>
      
      </div>
      </div> 
      <div className='apple-gray-back'>
        <div className='gray-font sanz-thin'>
        <span className='ink-gradient'>INK</span>BRUSH.™
        </div>
        <div className='deeper-gray-font '>
        $399 or as low as $18/mo. for<br/>24 mo. with financing*
        </div>

        <div className=''>
            <a className='purp-btn' href="mailto:cosmetinkbeauty@gmail.com?subject=InkBRUSH Financing&body=I would like to explore my financing options for the InkBRUSH.">Email to Apply for Financing &nbsp;<i class="fa-thin fa-envelope-open-dollar"></i></a>
        </div>


        <div className='black-bubble'>
      
            <div className='bubble-overlay'></div>
  
            <div className='gray-font sanz-reg font-14 bottom'>
             Stunning Results. <br/>Powdery, <br/>yet Precise.
            </div>

        </div>
        <br/>
        <div className='detail-box'>
        <div className="width-100 upcase let-spacing-5 font-size-9pt selected text-left main-product-name wht l">
                Description
        </div>
        <div className='box-desc wht'>
        <span className="bold wht">Ink Brush™</span>  is a revolutionary PMU machine designed to provide exceptional results for  the versatile needs of Permanent Cosmetic Professionals
        <br/><br/>
        <span className="bold wht">Ink Brush™</span>  is a short stroke  machine with a uniquely powerful hit, mimicking that of a long stroke machine.  With a 2.5mm stroke length and the power of a long stroke, it allows for clean,  consistent pigment deposit, while providing enhanced comfort and non-traumatic results.
        </div>
        <br/>
        <div className="width-100 upcase let-spacing-5 font-size-9pt selected text-left main-product-name wht l">
                Specs
        </div>
        <div className='box-desc wht'>
           <ul className='wht'>• Connectivity: RCA Cord (Included in the box)</ul>
           <ul className='wht'>• Stroke length: 2.5mm</ul>
           <ul className='wht'>• Nominal voltage: 8V</ul>
           <ul className='wht'>• Recommended voltage: 3.8v-6v (depending on your power supply)</ul>
           <ul className='wht'>• Max voltage: 9v</ul>
           <ul className='wht'>• Needle exit adjustment: 1.00 - 3.00 mm</ul>
           <ul className='wht'>• Grip size: 16 mm</ul>
           <ul className='wht'>• Weight: 2.32 oz (66g)</ul>
           <ul className='wht'>• Needle compatibility: universal tattoo cartridge docking system</ul>
           <br/>
           <ul className='wht'><span className='bold wht'>Enhanced ergonomic grip</span> for optimal comfort and reduced hand fatigue</ul>
           <br/>
           <ul className='wht'><span className='bold wht'>Ultra-slim design</span> for control and precision</ul>
        </div>
        </div>


      </div>
        
      </div> 
        );
    }