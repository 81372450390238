import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';


export default function BookingPage(props) {

    const {setBook, calendarID} = props

    useEffect(() => {
        if (calendarID)
        window.scrollTo(0, 0)
    }, [calendarID])
    

    return (
        <>
        <div className="schedule-open slide-in-bottom">
            <div onClick={() => setBook(false)} className="schedule-close"><i class="far fa-chevron-left"></i> Back</div>
            <div className="schedule-inside">
            <iframe className="schedule" src={`https://app.acuityscheduling.com/schedule.php?owner=17444389&calendarID=${calendarID}`} title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
        </div>
        </div>
        </>
    )
}