import axios from 'axios';

export const fetchSite = (site_id) => {
    return axios.get(`/api/sites/${site_id}`)
}

export const fetchFooter = (site_id) => {
    return axios.get(`/api/footers/${site_id}`)
}

export const fetchMobileNavbar = (site_id) => {
    return axios.get(`/api/mobilenavbars/${site_id}`)
}

export const fetchTeamMembers = (site_id) => {
    return axios.get(`/api/artists/${site_id}`)
}

export const fetchTeamMember = (site_id, name) => {
    return axios.get(`/api/artists/${site_id}/${name}`)
}

