import { connect } from 'react-redux';
import { fetchTeamMembers } from '../actions/site_actions';
import Team from './team';

const mapStateToProps = (state) => {
  return {
    site: state.sites.site,
    team: state.sites.team
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTeamMembers: site_id => dispatch(fetchTeamMembers(site_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Team);