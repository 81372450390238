import * as BrandsAPIUtil from '../util/brands_api_util';



export const RECEIVE_PRODUCT_BRANDS = "RECEIVE_PRODUCT_BRANDS";



export const receiveProductBrands = productBrands => ({
    type: RECEIVE_PRODUCT_BRANDS,
    productBrands
});




export const fetchProductBrands = (site_id) => dispatch => {
        BrandsAPIUtil.fetchProductBrands(site_id)
    .then(productBrands => dispatch(receiveProductBrands(productBrands)));
}



