import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import ProductsByCategory from './category_container';
import SelectedProduct from './selected_product';
import MobileNavbar from '../mobilenavbar_container'
import Cartsum from '../cart_sum_container'
import Product from './product';

export default function Collectionsbycategory(props) {
  

  const { fetchSite, site, products, 
          fetchProducts, fetchProductCategories,
          productCategories, fetchProductBrands, productBrands, fetchSelectedProduct,
        fetchSections, sections, match } = props

  const [siteId, setSiteId] = useState(null)
  const [state, setState] = useState(false)
  const [categories, setCategories] = useState(null)
  const [brands, setBrands] = useState(null)
  const [viewBySelected, setViewBySelected] = useState(2)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [open, setOpen] = useState(true)

  const [update, setUpdate] = useState(false)
  const [cart, setCart] = useState(false)
  const [added, setAdded] = useState(null)

  const subdomain = window.location.hostname.split('.')[0]
  const domain = window.location.href.split('.')[1]
  var id = match.params.id ? match.params.id : domain /* (domain == 'oworld' ? subdomain : domain) */
  var category = match.params.category ? match.params.category : null

  console.log(id)
  console.log(domain)
  console.log(window.location.hostname)
  console.log(window.location)
  console.log(category)


  useEffect(() => {

}, []) 

useEffect(() => {
    if (!site)
    fetchSite(id)
}, [site]) 

useEffect(() => {
  if (site) {
  setSiteId(site[0]._id)
  }
}, [site]) 

useEffect(() => {
  if (site && siteId)
  fetchProductBrands(siteId)
 
}, [site, siteId]) 

useEffect(() => {
    if (category) {
        setCategories(category)
    }
   
  }, [category]) 

useEffect(() => {
    if (category && siteId)
    fetchProducts(siteId, category)
   
  }, [siteId, category]) 
  

useEffect(() => {
  if (productBrands)
  setBrands(productBrands)
}, [productBrands]) 



    
function showCart() {
  cart ? setCart(false) : setCart(true)
}

function openShop() {
  open ? setOpen(false) : setOpen(true)
}

let total = 0

const $CART = {
  KEY: 'cosmetinkshopcartA2',
  contents: [],
 
  init(){
    //check local storage and initialize the contents of CART.contents
    let _contents = localStorage.getItem($CART.KEY);
    if (_contents) {
        
      $CART.contents = JSON.parse(_contents);

    } else {
      $CART.contents = [
      ];
      $CART.sync()
   
    }
  
  },


  async sync() {
    let _cart = JSON.stringify($CART.contents);
    await localStorage.setItem($CART.KEY, _cart);

    
  },
  find(id) {
  let match = $CART.contents.filter(item => {
    if(item.id == id)
    return true;
  });
  if (match && match[0])
  return match[0];
},
add(prod) {
  if($CART.find(prod._id)) {
    $CART.increase(prod._id, 1);
 
  }else{
    let arr = PRODUCTS.filter(product => {
      if(product._id == prod._id) {
        return true;
      }


    });
    if (arr && arr[0]) {
      let obj = {
        id: arr[0]._id,
        title: arr[0].name,
        qty: 1,
        itemCost: arr[0].cost,
        image: arr[0].image1
      };
      $CART.contents.push(obj);
      $CART.sync()
 
    } else {
      console.error("Invalid Product")
    }
  }
  $CART.findSum()
},
increase(id, qty=1) {
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty + qty;
      return item;
  });
  $CART.sync()
  updateCart()

},
reduce(id, qty=1){
  $CART.contents = $CART.contents.map(item => {
    if(item.id === id)
      item.qty = item.qty - qty;
      return item;
  });
  $CART.contents.forEach(async item => {
    if(item.id === id && item.qty === 0)
    $CART.remove(id);
  });
  $CART.sync()
  updateCart()
},
remove(id) {
  $CART.contents = $CART.contents.filter(item => {
    if(item.id !== id)
    return true;
  })
  $CART.sync()
  updateCart()
},
empty() {
  $CART.contents = [];
  $CART.sync()
  updateCart()
},

setTransactionID(transId) {
  $CART.transactionID = transId
},

findSum() {

      let sum = $CART.contents.forEach(item => {
          total = total + item.qty
          return sum
      })

    
     
},

sort(field='title') {
  let sorted = $CART.contents.sort( (a, b) => {
    if (a[field] > b[field]) {
      return 1;
    }else if(a[field] < b[field]){
      return -1;
    }else {
      return 0
    }
  });
  return sorted;
}

}

function addToCart(prod) {
 
  $CART.add(prod)
  showCart()
  setAdded(prod._id)
  setTimeout(() => {
    setAdded(null)
  }, 400);
}


function updateCart() {
  update ? setUpdate(false) : setUpdate(true)
}


let PRODUCTS = []
PRODUCTS = products
$CART.init()
$CART.findSum()


    
      return (

        

      


  
        
        <div className=" fade-in ">
          <div className="">
        <Cartsum cartupdate={cart}/>
   
          
 
          <div className="">  
          <div className="fade-in full-products">
                      {brands && products && Object.keys(brands).map((key2) => {
                        return (
                            <>
                              
                               {Object.keys(products).map((key) => {
                                if (products[key].bname === brands[key2].name)
                                return (
                             <Product
                              product = {products[key]}
                              bname={products[key].brand}
                              name={products[key].name}
                              image1={products[key].image1}
                              image2={products[key].image2}
                              desc={products[key].desc}
                              desc2={products[key].desc2}
                              cost={products[key].cost}
                              size={products[key].size}
                              subcategory={products[key].subcategory}
                              category={products[key].category}
                              addToCart={addToCart}
                              added={added}
                              setSelectedProduct={setSelectedProduct}
                              key={products[key]._id}
                              />
                            )
                        })}
       
                            </>
                         
                        )})
                           
                        
                      }
            
                      
                   
  
                      </div>
          </div> 


          </div></div> 
        );
    }