import axios from 'axios';


export const fetchProducts = (site_id, category) => {
    return axios.get(`/api/products/${site_id}/${category}`)
}

export const fetchSelectedProduct = (site_id, selectedProduct) => {
    return axios.get(`/api/selectedproducts/${site_id}/${selectedProduct}`)
}

export const fetchProductCategories = (site_id) => {
    return axios.get(`/api/products/${site_id}`)
}

