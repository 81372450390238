import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Team from './team_container';
import { fetchSite } from '../util/sites_api_util';
import Cart from '../components/cart/cart'

export default function MobileNavbar(props)  {

    const { site, match, mobilenavbar, fetchMobileNavbar, 
      products, selectedProduct, fetchProductCategories, productCategories, fetchSelectedProduct,
      createTransaction, newTransaction  } = props

    const [fold, setFold] = useState(false)
    const [menu, setMenu] = useState(false)
    const [navSite, setNavSite] = useState(null)
    const [cart, setCart] = useState(false)
    const [update, setUpdate] = useState(false)
    const [selectProduct, setSelectProduct] = useState(null)
    const [cartQty, setCartQty] = useState(0)
    const [cartContents, setCartContents] = useState([])
    const [prevDomain, setPrevDomain] = useState(null)
    const [success, setSuccess] = useState(false)
    const [catLinkOpen, setCatLinkOpen] = useState(null)
    const [scrolled, setScrolled] = useState(false)
    const [categories, setCategories] = useState(null)
    const [siteId, setSiteId] = useState(null)

    const subdomain = window.location.hostname.split('.')[0]
    const domain = window.location.href.split('.')[1]
    const pathName = window.location.pathname

    const productPath = window.location.pathname.split('/')[2]
    var id = (domain == 'oworld' ? subdomain : domain)

    useEffect(() => {
        if (!site)
        fetchSite(id)
 
    }, [site])

    useEffect(() => {
        if (site && siteId)
        fetchProductCategories(siteId)
       
      }, [site, siteId]) 

      useEffect(() => {
        if (site) {
        setSiteId(site[0]._id)
 
        }
      }, [site]) 
      
      useEffect(() => {
        if (productCategories)
        setCategories(productCategories)
      }, [productCategories]) 

    useEffect(() => {
        if (!site)
        fetchSite(id)
 
    }, [site])



   useEffect(() => {
        if (site) {
        fetchMobileNavbar(site[0]._id)
        setNavSite(site[0])
     
        }
    }, [site]) 

    useEffect(() => {
        if (selectedProduct) {
        setSelectProduct(selectedProduct[0])
        }
    }, [selectedProduct]) 

 

    useEffect(() => {
      if (pathName && !prevDomain) {
      setPrevDomain(pathName)
      }

      if (pathName && prevDomain !== pathName) {
        setPrevDomain(pathName)
        setCart(false)
     
        }


  }, [pathName, prevDomain]) 

 function setWindowHeight() {
   if (window.scrollY > 50) {
    setScrolled("blur") } else {
        setScrolled("")
    }
  }

  window.addEventListener("scroll", setWindowHeight);


 const brands = site ? site[0].brands : null

function setCatLinks(id) {
    catLinkOpen === id ? setCatLinkOpen(null) : setCatLinkOpen(id)
}

function setMenuOpen() {
    menu ? 
    setMenu(false) : setMenu(true)
}



        return (
            mobilenavbar ? (
            <>
            <div className={`mobile-navbar-top ${site[0].navColor} blur`}>
         
            <Link to={`/`} onClick={() => setSelectProduct(null)} className="navbar-logo">
            <img className="nav-logo" src={site[0].logo}></img>
            </Link>
            </div>

           

            <button onClick={setMenuOpen} className="menu-button">
            <div className={ `hamburger-one`}></div>
            <div className={ `hamburger-two`}></div>
            </button>

            {menu ?
            
            <div className={menu ? "pos-absolute scale-up-ver-top" : "pos absolute scale-out-ver-top"}>
               
            <button onClick={setMenuOpen} className="menu-button">
            <div className={ menu ? `rotate-one` : `hamburger-one`}></div>
            <div className={ menu ? `rotate-two` : `hamburger-two`}></div>
            </button>

                     <div className='menu-body'>
  
                     {Object.keys(categories).map((key) => {
                        return (
                        <>
                            <Link to={`${categories[key].linkto}`}>
                            <div onClick={setMenuOpen} className="category-label-menu fade-in">{categories[key].name} </div>
                            </Link>
                        </>
                        )
                      })}
                      </div>
            </div> :  null}
   
    
            </>
            ) : null
       
        )
    }