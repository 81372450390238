import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

export default function LandingPage(props)  {
    const [menu, setMenu] = useState(false)

    const { fetchSite, site } = props


    const subdomain = window.location.hostname.split('.')[0]
    const domain = window.location.href.split('.')[1]
    var id = props.match.params.id /* (domain == 'oworld' ? subdomain : domain) */

    console.log(id)
    console.log(domain)
    console.log(window.location.hostname)
    console.log(window.location)

    /*
    change app.js locations instead of /:id just /
    change links from /:id/:name to /:name etc
    */


    useEffect(() => {
        document.body.classList.remove('is-scrollLocked')
    }, [])

    useEffect(() => {
        if (!site)
        fetchSite(id)
    }, [site]) 



    function openMenu() {
        menu ? setMenu(false) : setMenu(true)
    }

        return (
            site ? (
            <>
            <div className="main-page">
                <div className={`front-page-title ${site[0].font}`}>
                    {site[0].calltoactionText ? 
                    site[0].calltoactionText : 
                    <img className="front-page-logo" src={site[0].logo}>
                    </img>
                    }
                 
                </div>
            
                <Link to={`/services`} className={site[0].gradientBtn ? `get-started` : `entry-btn`}>
                    <span className="btn-text">{site[0].calltoaction}</span>
                </Link>

                <img className={`landing-page-image ${site[0].landing_page_image_width ? null : `match-height`}`} src={site[0].landing_page_image}></img>
                {site[0].opacityOverlay ? 
                <div className="opacity-overlay"></div> : null }
            </div>
            </>
            ) : null
       
        )
    }
