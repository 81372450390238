import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Cartsum from '../cart_sum_container'

export default function SelectedProduct(props) {

  const { addToCart, fetchSite, site, match, product,
    fetchProductCategories, fetchProducts, fetchSelectedProduct, siteName } = props
  const [siteId, setSiteId] = useState(null)
  const [products, setProducts] = useState([])
  const [productName, setProductName] = useState(null)
  const domain = window.location.href.split('.')[1]
  var id = match.params.id ? match.params.id : domain
  var name = match.params.name
  var category = match.params.category
  const [productObj, setProductObj] = useState(null)

  useEffect(() => {
    if (!site)
    fetchSite(id)
}, [site]) 

useEffect(() => {
    if (site && siteId)
    fetchProductCategories(siteId)
  }, [site, siteId]) 

useEffect(() => {
  if (site)
  setSiteId(site[0]._id)
}, [site]) 

useEffect(() => {
    if (siteId && category)
    fetchProducts(siteId, category)
}, [siteId, category]) 

useEffect(() => {
    if (products)
    setProducts(products)
  }, [products]) 

useEffect(() => {
  if (name)
    setProductName(name)
  }, [name]) 

  useEffect(() => {
    if (name && siteId)
    fetchSelectedProduct(siteId, name)

  }, [name, siteId]) 
  
  useEffect(() => {
    if (product)
    setProductObj(product[0])
  }, [product]) 

  useEffect(() => {
    if (products)
    setProducts(products)
  }, [products]) 
  
  console.log(name)

    return (
     productObj ? (
      <div className="shop-main">
      <Cartsum />
      <div className="shop slide-in-bottom">
        
      <div className="category-label-3">{site[0].name} / <Link to={`/cosmetink/collections/${productObj.category}`}>{productObj.category}</Link> / {productObj.bname} / <Link to={`/cosmetink/collections/${productObj.category}/${productObj.name}`}>{productObj.name}</Link></div>
      <br/><br/>
      <div className="fade-in selected-product">
      
 
      
       <div className="product-name">
       <img className="width-100" src={productObj.image1} />
       <br/>

       <div className='bname brand-name'>
        {productObj.bname}
       </div>
       <span className="width-100 upcase let-spacing-5 font-size-9pt selected text-left main-product-name">{productObj.name}</span>
       <span className="width-100 upcase let-spacing-5 font-size-9pt selected text-left">{productObj.size}</span>
        <div className="width-100 upcase let-spacing-5 font-size-9pt selected text-left cost">${productObj.cost}</div>

       
        <br/>
        <div className=" width-100 upcase let-spacing-5 font-size-9pt font-weight-300 text-left product-desc">{productObj.desc}</div>

        <div className="width-100 upcase let-spacing-5 font-size-9pt font-weight-300 text-left product-desc">{productObj.desc2}</div>
       </div>
      
      </div>
      </div>
      </div> ) : null
        );
    }