import { connect } from 'react-redux';
import { fetchMobileNavbar, fetchSite } from '../actions/site_actions';
import MobileNavbar from './mobilenavbar';
import { fetchProducts, fetchSelectedProduct, fetchProductCategories } from '../actions/product_actions'
import {createTransaction} from '../actions/transaction_actions'

const mapStateToProps = (state) => {
  return {
    site: state.sites.site,
    mobilenavbar: state.sites.mobilenavbar,
    products: state.product.products,
    productCategories: state.product.productCategories,
    selectedProduct: state.product.selectedProduct,
    newTransaction: state.transaction.transaction
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMobileNavbar: site_id => dispatch(fetchMobileNavbar(site_id)),
    fetchSite: site_id => dispatch(fetchSite(site_id)),
    fetchProducts: (site_id, category) => dispatch(fetchProducts(site_id, category)),
    fetchProductCategories: site_id => dispatch(fetchProductCategories(site_id)),
    fetchSelectedProduct: (site_id, product_name) => dispatch(fetchSelectedProduct(site_id, product_name)),
    createTransaction: (transactionData) => dispatch(createTransaction(transactionData))
}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileNavbar);