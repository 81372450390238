import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import BookingPage from './booking_page';

export default function Page(props)  {

    const { site, page, match, fetchTeamMember, member, fetchSite} = props
    const [readMore, setReadMore] = useState(false)
    const [bookingPage, setBookingPage] = useState(false)
    const [site_id, setSiteId] = useState(null)
    
    const subdomain = window.location.hostname.split('.')[0]
    const domain = window.location.href.split('.')[1]
    var id = props.match.params.name /* (domain == 'oworld' ? subdomain : domain) */



    useEffect(() => {
        if (!site)
        fetchSite(props.match.params.name)
        setSiteId(id)
    }, [site])

   /* useEffect(() => {
        if (match && site) {
            fetchTeamMember(site[0]._id, props.match.params.name)
        }
    }, [match, site]) */

    function setShowMore() {
        readMore ? setReadMore(false) : setReadMore(true)
    }

    function setBooking() {
        bookingPage ? setBookingPage(false) : setBookingPage(true)
    }

        return (
            member ? (
            <>
            <div className="full-background">
            <div className="page">
            <div className="slide-in-bottom">
                    <div className="PMU-detail-page-title-2"><img className="artist-image-2" src={member[0].image} alt=""/><a className="instagram" href={`${member[0].instagram}`}>
                    <i class="fab fa-instagram" aria-hidden="true"></i></a></div>
                    <div className="artist-position">{member[0].title}
                    </div>

                    <div className="artist-page-title"> <br/> <a className="read-more-2" href={`${member[0].instagram}`}>VIEW MY WORK | BEFORE & AFTER PHOTOS <i class="fa fa-external-link" aria-hidden="true"></i></a> </div>
                    <br/>
                    <div className={readMore ? "PMU-detail-page-dropped" : "PMU-detail-hidden" }>
                        {member[0].bio}
                    </div>
                    <div className="button-read-more"><a className={!readMore ? "read-more-2" : "read-less"} onClick={setShowMore}>READ MORE <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                    <a className={readMore? 'read-more-2' : 'read-less'} onClick={setShowMore}><i className="fa fa-chevron-left" aria-hidden="true"></i> READ LESS</a>
                    </div>
             
            <div onClick={setBooking} className="schedule-btn">BOOK NOW WITH {`${member[0].name}`} </div>
            </div>
            </div>
            </div>
            {bookingPage ?
                <BookingPage setBook={setBooking} calendarID={`${member[0].calendarID}`}/>
                : null
                }
            </>
            ) : null
       
        )
    }